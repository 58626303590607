<template>
  <div id="app" class="container">
    <h1>to do list</h1>
    <form>
      <input type="text" required />
      <button>Ajouter</button>
    </form>
    <ul></ul>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      items: [],
    };
  },

  mounted() {
    const form = document.querySelector("form");
    const input = document.querySelector("form > input");
    const ul = document.querySelector("ul");

    form.addEventListener("submit", (event) => {
      event.preventDefault();
      const value = input.value;
      input.value = "";
      addItem(value);
    });

    const displayItems = () => {
      const itemsNode = this.items.map((item, index) => {
        if (!item.editMode) {
          return displaySingleItem(item, index);
        } else {
          return displaySingleItemEditMode(item, index);
        }
      });

      ul.textContent = "";
      ul.append(...itemsNode);
    };

    const displaySingleItem = (item, index) => {
      const li = document.createElement("li");
      const span = document.createElement("span");
      span.textContent = item.text;

      item.finished ? span.classList.add("done") : "";

      const btnEdit = document.createElement("button");
      const iconEdit = document.createElement("i");
      iconEdit.classList.add("fas", "fa-pencil-alt");
      btnEdit.appendChild(iconEdit);
      btnEdit.addEventListener("click", (event) => {
        event.stopPropagation();
        toggleEditMode(index);
      });

      const btnDelete = document.createElement("button");
      const iconDelete = document.createElement("i");
      iconDelete.classList.add("fas", "fa-trash-alt");
      btnDelete.appendChild(iconDelete);
      btnDelete.addEventListener("click", (event) => {
        event.stopPropagation();
        deleteItem(item, index);
      });

      const btnCheck = document.createElement("button");
      const iconCheck = document.createElement("i");
      iconCheck.classList.add("fas", "fa-check-square", "done");
      btnCheck.appendChild(iconCheck);
      btnCheck.addEventListener("click", (event) => {
        event.stopPropagation();
        checkItem(index);
      });

      !this.items[index].finished
        ? li.append(span, btnCheck, btnEdit, btnDelete)
        : li.append(span, btnEdit, btnDelete);

      return li;
    };

    const displaySingleItemEditMode = (item, index) => {
      const li = document.createElement("li");
      const input = document.createElement("input");
      input.value = item.text;

      const btnSave = document.createElement("button");
      const iconSave = document.createElement("i");
      iconSave.classList.add("fas", "fa-check-circle");
      btnSave.appendChild(iconSave);
      btnSave.addEventListener("click", (event) => {
        event.stopPropagation();
        editItem(input, index);
      });

      li.append(input, btnSave);
      return li;
    };

    const toggleEditMode = (index) => {
      this.items[index].editMode = !this.items[index].editMode;
      displayItems();
    };

    const deleteItem = (index) => {
      this.items.splice(index, 1);
      displayItems();
    };

    const editItem = (input, index) => {
      this.items[index].text = input.value;
      input.value = "";
      toggleEditMode(index);
      displayItems();
    };

    const checkItem = (index) => {
      this.items[index].finished = true;
      displayItems();
    };

    const addItem = (text) => {
      this.items.push({
        text,
        finished: false,
        editMode: false,
      });
      displayItems();
    };

    displayItems();
  },
};
</script>

<style>
@import "../src/assets/style.css";
</style>
